<template>
  <div>
    <ul class="clearfix">
      <li class="fl clearfix">
        <el-form-item label="Bank Address" prop="BankAddress">
          <el-input v-model="International.BankAddress"></el-input>
        </el-form-item>
      </li>
      <li class="fr clearfix">
        <el-form-item label="Bank Beneficiary Name" prop="BankBeneficiaryName">
          <el-input v-model="International.BankBeneficiaryName"></el-input>
        </el-form-item>
      </li>
    </ul>
    <ul class="clearfix">
      <li class="fl clearfix">
        <el-form-item label="Account Number/IBAN" prop="AccountNumber">
          <el-input v-model="International.AccountNumber"></el-input>
        </el-form-item>
      </li>
      <li class="fr clearfix">
        <el-form-item label="Account Holder's Address" prop="AccountHolderAddress">
          <el-input v-model="International.AccountHolderAddress"></el-input>
        </el-form-item>
      </li>
    </ul>
    <ul class="clearfix">
      <li class="fl">
        <el-form-item label="Swift" prop="Swift">
          <el-input v-model="International.Swift"></el-input>
        </el-form-item>
      </li>
      <li class="fr">
        <el-form-item label="ABA/Sort Code" prop="ABA">
          <el-input v-model="International.ABA"></el-input>
        </el-form-item>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      International: {
        BankAddress: '',
        BankBeneficiaryName: '',
        AccountNumber: '',
        AccountHolderAddress: '',
        Swift: '',
        ABA: ''
      }
    };
  },
  watch: {
    International: {
      deep: true,
      handler(form) {
        this.$emit('formData', form);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.fl,
.fr {
  margin-bottom: 5px;
  width: 42%;
}
@media (max-width: 414px) {
  .fl,
  .fr {
    width: 100%;
  }
}
</style>
