<template>
  <div id="paymentDetails">
    <div class="content_box">
      <div class="inner_max">
        <div class="payment_content_box">
          <div class="form_wrapper" v-if="!success">
            <h3>{{ $t('menu.payDetails') }}</h3>
            <div class="form_box">
              <h4>{{ $t('paymentDetails.subHeader') }}</h4>
              <div class="form_inner">
                <el-form label-position="top" class="is_sticky">
                  <div
                    v-html="$t('paymentDetails.tip', { individualUserName: individualUserName })"
                    class="bankTransferText"
                  ></div>
                  <div class="unionpay-tip" v-html="$t('deposit.unionPay.tip')"></div>
                  <div class="payment_method">
                    <div class="customLabel">
                      <span class="required_icon">*</span>{{ $t('paymentDetails.payMethod') }}
                    </div>
                    <el-select v-model="paymentMethod" @change="changeView($event)" data-testid="paymentMethod">
                      <el-option
                        v-for="(method, index) in paymentMethods"
                        :key="method.value"
                        :label="$t(method.label)"
                        :value="index"
                        :data-testid="method.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form>
                <component :is="view" @onSuccess="onSuccess"></component>
              </div>
            </div>
            <RegisterHistory></RegisterHistory>
          </div>
          <Result v-else>{{ resultMessage }}</Result>
          <Detail
            :display.sync="detailShow"
            :name="name"
            :creditCardNumber="creditCardNumber"
            :expireDate="expireDate"
          ></Detail>
          <UnionpayDetails :display.sync="unionPayCardDetailShow" :cardId="cardId"></UnionpayDetails>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreditCardRegister from '@/components/payment/CreditCardRegister';
import BankTransferRegister from '@/components/payment/bankTransferRegister/Index';
import UnionPayRegister from '@/components/payment/UnionPayRegister';
import RegisterHistory from '@/components/payment/RegisterHistory';
import Detail from '@/components/payment/details';
import UnionpayDetails from '@/components/payment/details/UnionpayDetails';
import Result from '@/components/Result';

export default {
  components: {
    CreditCardRegister,
    BankTransferRegister,
    UnionpayDetails,
    UnionPayRegister,
    RegisterHistory,
    Detail,
    Result
  },
  data() {
    return {
      view: '',
      success: false,
      detailShow: false,
      unionPayCardDetailShow: false,
      cardId: null,
      paymentMethod: '',
      paymentMethods: this.$platform.paymentMethods,
      name: '',
      creditCardNumber: '',
      expireDate: '',
      individualUserName: this.$store.state.common.individualUserName,
      resultMessage: ''
    };
  },
  methods: {
    changeView(value) {
      this.view = this.paymentMethods[value].component;
    },
    onSuccess(data) {
      this.success = true;
      this.resultMessage = data.resultMessage;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/financeInfo.scss';

.unionpay-tip {
  margin-bottom: 2rem;
}
</style>
