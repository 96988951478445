<template>
  <div id="message_info">
    <div class="dialog_wrapper">
      <el-dialog :visible="display" top="0" @close="close">
        <div slot="title">
          <img src="@/assets/images/close.png" alt="" @click="close" />
        </div>
        <div class="dialog_body clearfix">
          <el-form label-position="top">
            <el-form-item :label="$t('common.field.name')">
              <span>{{ name }}</span>
              <!-- <img src="@/assets/images/dialog_true.png" alt /> -->
            </el-form-item>
            <el-form-item :label="$t('common.field.ccNum')">
              <span>{{ cardNumber }}</span>
              <!-- <img src="@/assets/images/dialog_true.png" alt /> -->
            </el-form-item>
            <el-form-item :label="$t('common.field.expDate')">
              <span>{{ expireDate }}</span>
              <!-- <img src="@/assets/images/dialog_true.png" alt /> -->
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Detail',
  props: {
    display: Boolean,
    name: String,
    creditCardNumber: String,
    expireDate: String
  },
  computed: {
    cardNumber: function () {
      return (
        this.creditCardNumber.substring(0, 4) +
        ' ' +
        this.creditCardNumber.substring(4, 8) +
        ' ' +
        this.creditCardNumber.substring(8, 12) +
        ' ' +
        this.creditCardNumber.substring(12)
      );
    }
  },
  methods: {
    close() {
      this.$emit('update:display', false);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/components/payment/detail.scss';
</style>
