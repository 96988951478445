<template>
  <el-form label-position="top" status-icon :model="bankTransferForm" :rules="bankTransferRules" ref="bankTransferForm">
    <div class="form_rigister">
      <ul class="clearfix">
        <li class="fl">
          <WithdrawlSharedOptions
            v-model="bankTransferForm.country"
            :list="$platform.country_transferOptions"
          ></WithdrawlSharedOptions>
        </li>
        <li v-show="bankTransferForm.country" class="fr">
          <el-form-item label="Bank Name" prop="BankName">
            <el-input v-model="bankTransferForm.BankName"></el-input>
          </el-form-item>
        </li>
      </ul>
      <component :is="currentComponent" @formData="getformData"></component>
    </div>
    <div class="form_last" v-show="bankTransferForm.country">
      <div class="info">
        <p>
          Please upload a colour copy or photograph of your bank statementm, ensuring that it clearly shows your full
          name and address. Please note: If you intend to fund your account using different bank accounts you must
          repeat this process for each bank account.
        </p>
      </div>
      <div>
        <el-form-item prop="fileList" class="center">
          <vUpload :key="reRender" :limit="1" v-on:updateFileInfo="updateFileInfo"> </vUpload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('bankTransferForm')">SUBMIT</el-button>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import vUpload from '@/components/vUpload';
import WithdrawlSharedOptions from '@/components/form/WithdrawlSharedOptions';
import Australia from '@/components/payment/bankTransferRegister/Australia';
import International from '@/components/payment/bankTransferRegister/International';
import Hover from '@/components/Hover';
import { apiCredit_card } from '@/resource';
export default {
  components: {
    vUpload,
    WithdrawlSharedOptions,
    Australia,
    International
  },
  data() {
    const require = {
      required: true,
      trigger: 'blur'
    };
    return {
      reRender: 0,
      currentComponent: '',
      bankTransferForm: {
        country: null,
        fileList: ''
      },
      bankTransferRules: {
        BankName: [require],
        BSB: [require],
        BankAccountNumber: [require],
        BankBeneficiaryName: [require],
        BankAddress: [require],
        AccountNumber: [require],
        AccountHolderAddress: [require],
        fileList: [
          {
            required: true,
            message: 'Credit Card Photo is required',
            trigger: 'blur'
          }
        ]
      },
      resultMessage: this.$t('paymentDetails.result.defaultSuccessMessage')
    };
  },
  watch: {
    'bankTransferForm.country'(country) {
      this.reRender += 1;
      this.bankTransferForm = {
        country: country,
        fileList: ''
      };
      switch (country) {
        case 1:
          this.currentComponent = Australia;
          break;
        case 2:
          this.currentComponent = International;
          break;
      }
    }
  },
  methods: {
    getformData(formData) {
      this.bankTransferForm = { ...this.bankTransferForm, ...formData };
    },
    submitForm(formName) {
      this.$refs.bankTransferForm.validate(valid => {
        if (valid) {
          console.log('驗證過了');
          this.$emit('onSuccess', {
            resultMessage: this.resultMessage
          });
        } else {
          return false;
        }
      });
    },
    updateFileInfo(fileInfo) {
      this.bankTransferForm.fileList = fileInfo.fileList[0];
      this.$refs['bankTransferForm'].validateField('fileList');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/payment/register.scss';
</style>
