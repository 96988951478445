<template>
  <el-form label-position="top" :model="creditCardForm" status-icon :rules="creditCardRules" ref="creditCardForm">
    <div class="form_middle">
      <div class="nameCard">
        <el-form-item :label="$t('common.field.nameOnCard')" prop="nameCard">
          <el-input v-model="individualUserName" :disabled="true"></el-input>
          <img src="@/assets/images/label_icon.png" alt class="label_icon" />
          <i18n path="common.fund.infoHover" tag="hover">
            <template v-slot:must>
              <u>{{ $t('common.fund.must') }}</u>
            </template>
          </i18n>
        </el-form-item>
      </div>
      <ul class="clearfix">
        <li class="fl_ customLabel creditCard clearfix">
          <p><span class="required_icon">*</span>{{ $t('paymentDetails.cc.field.ccNum') }}</p>
          <el-form-item prop="creditNumberFirst" class="fl">
            <el-input
              class="creadit_input"
              maxlength="6"
              v-model="creditCardForm.creditNumberFirst"
              :placeholder="$t('paymentDetails.cc.field.first6')"
            ></el-input>
          </el-form-item>
          <div class="middle middle_margin">00 0000</div>
          <el-form-item prop="creditNumberLast" class="fr">
            <el-input
              class="creadit_input"
              maxlength="4"
              v-model="creditCardForm.creditNumberLast"
              :placeholder="$t('paymentDetails.cc.field.last4')"
            ></el-input>
          </el-form-item>
        </li>
        <li class="fr_ customLabel date clearfix">
          <p><span class="required_icon">*</span>{{ $t('common.field.expDate') }}</p>
          <el-form-item prop="month" class="month fl">
            <Month :year="creditCardForm.year" v-model="creditCardForm.month"></Month>
          </el-form-item>
          <el-form-item prop="year" class="year fr">
            <Year v-model="creditCardForm.year"></Year>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div class="form_last">
      <div class="info">
        <p>{{ $t('common.fund.ccUploadReminder') }}</p>
      </div>
      <ul class="clearfix">
        <li class="fl">
          <el-form-item prop="fileList" class="fr">
            <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo"></vUpload>
          </el-form-item>
        </li>
        <li class="fr">
          <p class="creditCardShow creditCardShowFour">{{ creditCardShowFour }}</p>
          <p class="creditCardShow creditCardShowTwo">{{ creditCardShowTwo }}</p>
          <p class="creditCardShow creditNumberLastShow">{{ creditCardForm.creditNumberLast }}</p>
          <p class="creditCardShow monthShow">{{ creditCardForm.month }}</p>
          <p class="creditCardShow yearShow">{{ creditCardForm.year.toString().substr(2, 4) }}</p>
          <img :src="masterOrVisaUrl" class="creditCardShow visaOrMaster" :class="visaClass" />
          <img src="@/assets/images/financial_card.png" alt />
        </li>
      </ul>
      <el-form-item>
        <el-button type="primary" @click="submitForm('creditCardForm')">{{ $t('common.button.submit') }}</el-button>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import vUpload from '@/components/vUpload';
import Month from '@/components/form/Month';
import Year from '@/components/form/Year';
import Hover from '@/components/Hover';
import { apiCredit_card } from '@/resource';
import { valiCreditNumberFirst, valiCreditNumberLast } from '@/util/validation';

export default {
  name: 'creditCardRegister',
  data() {
    return {
      creditCardForm: {
        creditNumberFirst: '',
        creditNumberLast: '',
        month: '',
        year: '',
        fileList: ''
      },
      creditCardRules: {
        creditNumberFirst: [
          {
            required: true,
            message: this.$t('common.formValidation.ccReq'),
            trigger: 'blur'
          },
          {
            validator: valiCreditNumberFirst,
            trigger: 'blur'
          }
        ],
        creditNumberLast: [
          {
            required: true,
            message: this.$t('common.formValidation.ccReq'),
            trigger: 'blur'
          },
          {
            validator: valiCreditNumberLast,
            trigger: 'blur'
          }
        ],
        month: [
          {
            required: true,
            message: this.$t('common.formValidation.expDateReq'),
            trigger: 'change'
          }
        ],
        year: [
          {
            required: true,
            message: this.$t('common.formValidation.expDateReq'),
            trigger: 'change'
          }
        ],
        fileList: [
          {
            required: true,
            message: this.$t('common.formValidation.filePhoto'),
            trigger: 'blur'
          }
        ]
      },
      resultMessage: this.$t('paymentDetails.result.defaultSuccessMessage')
    };
  },
  components: {
    vUpload,
    Month,
    Year,
    Hover
  },
  computed: {
    individualUserName() {
      return this.$store.state.common.individualUserName;
    },
    creditCardShowFour() {
      return `${this.creditCardForm.creditNumberFirst.toString().substr(0, 4)}`;
    },
    creditCardShowTwo() {
      return `${this.creditCardForm.creditNumberFirst.toString().substr(4, 6)}`;
    },
    masterOrVisaUrl() {
      const num = this.creditCardForm.creditNumberFirst.toString();
      if (num.substr(0, 1) == 4) return require(`@/assets/images/Visa.png`);
      else if (num.substr(0, 2) >= 51 && num.substr(0, 2) <= 55) return require(`@/assets/images/MASTER.png`);
      else return ``;
    },
    visaClass: function() {
      return {
        visa: this.creditCardForm.creditNumberFirst.toString().substr(0, 1) == 4 ? true : false
      };
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.submitCreditCard()
            .then(resp => {
              console.log(resp);
              if (resp.data == true)
                this.$emit('onSuccess', {
                  resultMessage: this.resultMessage
                });
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updateFileInfo(fileInfo) {
      this.creditCardForm.fileList = fileInfo.fileList[0];
      this.$refs['creditCardForm'].validateField('fileList');
    },
    submitCreditCard() {
      return apiCredit_card({
        user_id: this.$store.state.common.uuid,
        card_begin_six_digits: this.creditCardForm.creditNumberFirst,
        card_last_four_digits: this.creditCardForm.creditNumberLast,
        card_photo_path: this.creditCardForm.fileList,
        expiry_month: this.creditCardForm.month,
        expiry_year: this.creditCardForm.year,
        card_holder_name: this.individualUserName
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/payment/register.scss';
</style>
