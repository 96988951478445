<template>
  <el-form
    class="unionPayRegister"
    label-position="top"
    :model="unionPayForm"
    status-icon
    :rules="unionPayRules"
    ref="unionPayForm"
  >
    <div class="form_middle">
      <div class="nameBank">
        <el-form-item :label="$t('common.field.bankName')" prop="bankName">
          <el-select id="bankName" v-model="unionPayForm.bankName">
            <el-option v-for="(list, index) in bankNameLists" :key="index" :label="list" :value="list"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>
    <div class="form_rigister">
      <ul class="clearfix">
        <li class="fl">
          <el-form-item :label="$t('common.field.cardHolderName')" prop="cardHolderName">
            <el-input id="name" maxlength="128" v-model="unionPayForm.cardHolderName"></el-input>
          </el-form-item>
        </li>
        <li class="fr">
          <el-form-item :label="$t('common.field.nationalId')" prop="nationalId">
            <el-input id="nationalId" maxlength="20" v-model="unionPayForm.nationalId"></el-input>
          </el-form-item>
        </li>
      </ul>
      <ul class="clearfix">
        <li class="fl">
          <el-form-item :label="$t('common.field.bankCardNumber')" prop="cardNumber">
            <el-input id="cardNumber" maxlength="20" v-model="unionPayForm.cardNumber"></el-input>
          </el-form-item>
        </li>
        <li class="fr">
          <el-form-item :label="$t('common.field.reservedMobileNumber')" prop="cardPhoneNumber">
            <el-input id="cardPhoneNumber" maxlength="20" v-model="unionPayForm.cardPhoneNumber"></el-input>
          </el-form-item>
        </li>
      </ul>
      <ul class="clearfix">
        <li class="fl customLabel branchName">
          <el-form-item :label="$t('common.field.bankBranch')" prop="branchName">
            <div data-toggle="distpicker" :class="{ error: isError }">
              <select v-model="unionPayForm.province"></select>
              <select v-model="unionPayForm.city"></select>
            </div>
            <el-input
              id="branchName"
              v-model="unionPayForm.branch"
              :placeholder="$t('common.field.bankBranch')"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div class="form_last">
      <div class="info">
        <p>{{ this.$t('common.field.updateCardPhoto') }}</p>
      </div>
      <div>
        <el-form-item prop="cardPhotoFile" class="center">
          <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo"> </vUpload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('unionPayForm')">
            {{ $t('common.button.submit') }}
          </el-button>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>
<script>
import vUpload from '@/components/vUpload';
import { apiUnionpay_banklist, apiRegisterUnionPayCard_cp } from '@/resource';
import '@/assets/js/distpicker_admin';
import { validateNumber, valiAlphanumeric } from '@/util/validation';

export default {
  name: 'unionPayRegister',
  props: ['onSuccess'],
  data() {
    return {
      unionPayForm: {
        bankName: '',
        cardNumber: '',
        cardPhoneNumber: '',
        cardHolderName: '',
        province: '',
        city: '',
        branchName: '',
        nationalId: '',
        cardPhotoFile: '',
        branch: ''
      },
      unionPayRules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: 'change'
          }
        ],
        cardNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.cardReq'),
            trigger: 'blur'
          },
          {
            validator: validateNumber,
            trigger: 'blur'
          }
        ],
        cardPhoneNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.phoneNumberReq'),
            trigger: 'blur'
          },
          {
            validator: validateNumber,
            trigger: 'blur'
          }
        ],
        cardHolderName: [
          {
            required: true,
            message: this.$t('common.formValidation.cardOnNameReq'),
            trigger: 'blur'
          }
        ],
        branchName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBranchReq'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!this.unionPayForm.branch) {
                const message = this.$t('common.formValidation.bankBranchReq');
                callback(new Error(message));
              }
              callback();
            },
            trigger: 'blur'
          }
        ],
        nationalId: [
          {
            required: true,
            message: this.$t('common.formValidation.nationaIdReq'),
            trigger: 'blur'
          },
          {
            validator: valiAlphanumeric,
            trigger: 'blur'
          }
        ],
        cardPhotoFile: [
          {
            required: true,
            message: this.$t('common.formValidation.filePhotoReq'),
            trigger: 'blur'
          }
        ]
      },
      bankNameLists: [],
      resultMessage: this.$t('paymentDetails.result.unionPayCardSuccessMessage'),
      isError: ''
    };
  },
  components: {
    vUpload
  },
  computed: {
    compoundProperty() {
      return this.unionPayForm.province && this.unionPayForm.city;
    }
  },
  watch: {
    compoundProperty(bool) {
      if (bool) this.isError = '';
    },
    'unionPayForm.province'(value) {
      this.unionPayForm.city = '';
      this.unionPayForm.branchName = `${this.unionPayForm.province}-${this.unionPayForm.city}-${this.unionPayForm.branch}`;
    },
    'unionPayForm.city'(value) {
      this.unionPayForm.branchName = `${this.unionPayForm.province}-${this.unionPayForm.city}-${this.unionPayForm.branch}`;
    },
    'unionPayForm.branch'(value) {
      this.unionPayForm.branchName = `${this.unionPayForm.province}-${this.unionPayForm.city}-${this.unionPayForm.branch}`;
    }
  },
  methods: {
    submitForm(formName) {
      const vm = this;
      this.$refs[formName].validate(valid => {
        if (vm.unionPayForm.province == '' || vm.unionPayForm.city == '') {
          vm.isError = 'error';
          return false;
        }

        if (valid) {
          this.registerCard(this.unionPayForm)
            .then(resp => {
              console.log('resp', resp);
              if (resp.data.code == 0) {
                this.$emit('onSuccess', {
                  resultMessage: this.resultMessage
                });
              } else {
                return false;
              }
            })
            .catch(err => {
              this.$message({
                message: err,
                type: 'error'
              });
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updateFileInfo(fileInfo) {
      this.unionPayForm.cardPhotoFile = fileInfo.fileList[0];
      this.$refs['unionPayForm'].validateField('cardPhotoFile');
      console.log('file path', this.unionPayForm.cardPhotoFile);
    },
    registerCard(card) {
      console.log('card body: ', card);
      return apiRegisterUnionPayCard_cp(card);
    },
    getBankList() {
      return apiUnionpay_banklist().then(resp => {
        if (resp.data.code == 0) {
          this.bankNameLists = resp.data.data.map(list => {
            return list.p_key;
          });
        }
      });
    }
  },
  mounted() {
    $('[data-toggle="distpicker"]').distpicker();
    this.getBankList();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/payment/register.scss';
</style>
