<template>
  <div id="message_info">
    <div class="dialog_wrapper">
      <el-dialog :visible="display" top="0" @close="close">
        <div slot="title">
          <img src="@/assets/images/close.png" alt="" @click="close" />
        </div>
        <div class="dialog_body clearfix">
          <el-form label-position="top">
            <el-form-item :label="$t('common.field.bankName')">
              <span>{{ bankName }}</span>
              <!-- <img v-show="bankName" src="@/assets/images/dialog_true.png" alt /> -->
            </el-form-item>
            <el-form-item :label="$t('common.field.bankCardNumber')">
              <span>{{ bankCardNumber }}</span>
              <!-- <img v-show="bankCardNumber" src="@/assets/images/dialog_true.png" alt /> -->
            </el-form-item>
            <el-form-item :label="$t('common.field.bankBranch')">
              <span>{{ bankBranch }}</span>
              <!-- <img v-show="bankBranch" src="@/assets/images/dialog_true.png" alt /> -->
            </el-form-item>
            <el-form-item :label="$t('common.field.cardHolderName')">
              <span>{{ cardHolderName }}</span>
              <!-- <img v-show="cardHolderName" src="@/assets/images/dialog_true.png" alt /> -->
            </el-form-item>
            <el-form-item :label="$t('common.field.reservedMobileNumber')">
              <span>{{ reservedMobileNumber }}</span>
              <!-- <img v-show="reservedMobileNumber" src="@/assets/images/dialog_true.png" alt /> -->
            </el-form-item>
            <el-form-item :label="$t('common.field.nationalId')">
              <span>{{ nationalId }}</span>
              <!-- <img v-show="nationalId" src="@/assets/images/dialog_true.png" alt /> -->
            </el-form-item>
            <el-form-item :label="$t('common.field.cardPhoto')">
              <span><img :src="cardPhoto" alt /> </span>
              <!-- <img v-show="cardPhoto" src="@/assets/images/dialog_true.png" alt /> -->
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { apiGetCardDetials } from '@/resource';
export default {
  name: 'UnionpayDetails',
  props: { display: Boolean, cardId: Number },
  data() {
    return {
      bankName: '',
      bankCardNumber: '',
      bankBranch: '',
      cardHolderName: '',
      reservedMobileNumber: '',
      nationalId: '',
      cardPhoto: ''
    };
  },
  watch: {
    cardId() {
      this.queryCard().then(card => {
        console.log('card', card);
        this.bankName = card.bank_name;
        this.bankCardNumber =
          card.card_number.substr(0, 6) + '******' + card.card_number.substr(card.card_number.length - 4);
        this.bankBranch = card.branch_name;
        this.cardHolderName = card.card_holder_name;
        this.reservedMobileNumber = card.card_phone_number;
        this.nationalId = card.national_id_card;
        this.cardPhoto = card.card_photo_path;
      });
    }
  },
  methods: {
    close() {
      this.$emit('update:display', false);
    },
    queryCard() {
      return apiGetCardDetials({ cardId: this.cardId, cardType: 'UNIONPAY_CARD_CPS' }).then(resp => {
        console.log(resp);
        if (resp.data.code == 0) {
          return resp.data.data;
        } else {
          return [];
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/payment/detail.scss';
</style>
