<template>
  <ul>
    <ul class="clearfix">
      <li class="fl">
        <el-form-item label="BSB" prop="BSB">
          <el-input v-model="Au.BSB"></el-input>
        </el-form-item>
      </li>
      <li class="fr">
        <el-form-item label="Bank Account Number" prop="BankAccountNumber">
          <el-input v-model="Au.BankAccountNumber"></el-input>
        </el-form-item>
      </li>
    </ul>
    <ul class="clearfix">
      <li class="fl">
        <el-form-item label="Bank Beneficiary Name" prop="BankBeneficiaryName">
          <el-input v-model="Au.BankBeneficiaryName"></el-input>
        </el-form-item>
      </li>
      <li class="fr">
        <el-form-item label="Swift(Non AUD Accounts)" prop="swift">
          <el-input v-model="Au.Swift"></el-input>
        </el-form-item>
      </li>
    </ul>
  </ul>
</template>
<script>
export default {
  data() {
    return {
      Au: {
        BSB: '',
        BankAccountNumber: '',
        BankBeneficiaryName: '',
        Swift: ''
      }
    };
  },
  watch: {
    Au: {
      deep: true,
      handler(form) {
        this.$emit('formData', form);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.fl,
.fr {
  margin-bottom: 5px;
  width: 42%;
}

@media (max-width: 414px) {
  .fl,
  .fr {
    width: 100%;
  }
}
</style>
