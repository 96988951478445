<template>
  <div class="table_wrapper">
    <h3>{{ $t('paymentDetails.history.header') }}</h3>
    <div class="table_box">
      <el-table :data="displayData" style="width: 100%">
        <el-table-column :label="$t('common.column.date')">
          <template slot-scope="scope">{{ scope.row.create_time | date }}</template>
        </el-table-column>
        <el-table-column :label="$t('common.column.type')">
          <template slot-scope="scope">{{ scope.row.payment_type | paymentType }}</template>
        </el-table-column>
        <el-table-column :label="$t('common.column.number')">
          <template slot-scope="scope">
            <span v-if="scope.row.payment_type == 4 && ['工商银行'].includes(scope.row.bank_name)" style="color:gray">
              {{ scope.row.card_number }}
            </span>
            <u v-else-if="scope.row.payment_type == 1">
              <a @click.stop.prevent="showDetail(scope.$index, scope.row)">
                {{ scope.row.card_number }}
              </a>
            </u>
            <u v-else-if="scope.row.payment_type == 4">
              <a @click.stop.prevent="showUnionpayDetails(scope.$index, scope.row)">
                {{ scope.row.card_number }}
              </a>
            </u>
            <span v-else>{{ scope.row.card_number }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.column.status')">
          <template slot-scope="scope">{{ scope.row.status_display | status | transactionStatus }}</template>
        </el-table-column>
        <el-table-column prop="processed_notes" :label="$t('common.column.comment')"></el-table-column>
        <el-table-column prop="remove" :label="$t('common.column.remove')" min-width="60">
          <template slot-scope="scope">
            <a
              v-if="scope.row.payment_type === 4"
              class="remove"
              @click.stop.prevent="removeMethod(scope.$index, scope.row, 4)"
              >x</a
            >
          </template></el-table-column
        >
      </el-table>
    </div>
    <Pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></Pagination>
    <RemoveMethod
      :display.sync="removeMethodShow"
      :cardId="cardId"
      :cardMethod="cardMethod"
      @refreshTable="getHistoryList"
    ></RemoveMethod>
  </div>
</template>

<script>
import { apiHistoryList } from '@/resource';
import Pagination from '@/components/Pagination';
import RemoveMethod from '@/components/payment/details/RemoveMethod';
export default {
  name: 'registerHistory',
  components: {
    Pagination,
    RemoveMethod
  },
  data() {
    return {
      tableData: [],
      displayData: [],
      removeMethodShow: false,
      cardId: null,
      cardMethod: null
    };
  },
  methods: {
    showDetail(index, row) {
      this.$parent.name = row.card_holder_name;
      this.$parent.creditCardNumber = row.card_number;
      this.$parent.expireDate = row.expiry;
      this.$parent.detailShow = true;
    },
    showUnionpayDetails(index, row) {
      this.$parent.cardId = row.id;
      this.$parent.unionPayCardDetailShow = true;
    },
    removeMethod(index, row) {
      this.cardId = row.id;
      this.cardMethod = row.payment_type;
      this.removeMethodShow = true;
    },
    getHistoryList() {
      apiHistoryList({
        cardType: -1
      }).then(resp => {
        this.tableData = resp.data;
      });
    }
  },
  mounted() {
    this.getHistoryList();
  }
};
</script>
<style lang="scss" scoped>
a {
  color: rgb(251, 249, 249);
}
</style>
