<template>
  <div id="message_info">
    <div class="dialog_wrapper">
      <vDialog id="success" :visible.sync="visible" :visibleFooter="false">
        <div class="dialog_body">
          <img src="@/assets/images/dialog_remove.png" alt />
          <h2>{{ $t('common.field.confirmation') }}</h2>
          <h3>{{ $t('common.field.removeNote') }}</h3>
          <div class="form_button">
            <el-button class="el-button white_button" @click="display = false" data-testid="close">
              {{ $t('common.button.cancel') }}
            </el-button>
            <el-button class="el-button blue_button" @click="removeMethodDetails()" data-testid="submit">
              {{ $t('common.column.remove') }}
            </el-button>
          </div>
        </div>
      </vDialog>
    </div>
  </div>
</template>

<script>
import vDialog from '@/components/home/vDialog';
import { apiRemoveCard } from '@/resource';

export default {
  name: 'RemoveMethod',
  components: { vDialog },
  props: { display: Boolean, cardId: Number, cardMethod: Number },
  data() {
    return {
      visible: false,
      bankName: ''
    };
  },
  watch: {
    display(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:display', bool);
    }
  },
  methods: {
    removeMethodDetails() {
      this.loading = true;
      return apiRemoveCard({
        id: this.cardId,
        payment_type: this.cardMethod,
        is_del: 1
      })
        .then(resp => {
          if (resp.data === true) {
            this.loading = false;
            this.display = false;
            this.$emit('refreshTable');
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/payment/detail.scss';

.dialog_body {
  word-break: normal;
  text-align: center;

  h2 {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 1.5em;
    color: white;
    font-weight: bold;
  }

  h3 {
    font-size: 1.17em;
  }

  .form_button .el-button {
    margin-top: 40px;
    line-height: 1.5;
  }
}
</style>
