<template>
  <el-pagination
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next"
    :total="total"
    :current-page="currentPage"
    :page-sizes="[10, 25, 50, 100]"
    @current-change="handleCurrentChange"
    @size-change="handleSizeChange"
    data-testid="pagination"
  ></el-pagination>
</template>

<script>
export default {
  props: ['tableData'],
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      displayData: [],
      total: 0
    };
  },
  watch: {
    tableData(val) {
      this.updateData();
      this.total = Array.isArray(val) ? val.length : 0;
    }
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber;
      this.updateData();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.updateData();
    },
    updateData() {
      this.displayData = this.tableData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
      this.$emit('update:display-data', this.displayData);
    },
    resetPage() {
      this.currentPage = 1;
      this.updateData();
    }
  }
};
</script>
